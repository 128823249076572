import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Colophon from "../components/colophon";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/form";
import ContactFormReformer from "../components/formReformer";

function ContactUs() {
  return (
    <Layout>
      <Seo title="Contact Us - Private or Semi-Private Classes" />
      <div className="relative bg-gray-900" id="contact">
        <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
          <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
            <div
              className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#f21d92] to-[#9c0dd9776fff] opacity-25"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div className="relative mx-auto max-w-2xl">
          <div className="pb-1 px-4 sm:px-6">
            <div className="mx-auto max-w-xl text-center">
              <div className=" pt-10 "></div>
              <h2 className="text-2xl font-bold tracking-white text-white sm:text-2xl">
                Are you interested in Private or Semi-Private classes?
              </h2>
              <p className="my-6 text-lg leading-8 text-gray-300">
                Please complete this form and we get right back to you.
              </p>
            </div>
          </div>
          <div className="flex-shrink-0 items-center  pb-16 px-4 sm:px-6 lg:col-span-3  lg:px-8 xl:pl-12 ">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <ContactFormReformer s />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactUs;
